import avatarThumb from "../../skeleton-images/front1.png"; 

const data = [
  {
    avatar: avatarThumb,
    name: "Jasper",
    designation: "Community Manager",
  },    
  {
    avatar: avatarThumb,
    name: "Daniel",
    designation: "Project Artist",
  },    
  {
    avatar: avatarThumb,
    name: "Tom",
    designation: "Blockchain Dev",
  },    
  {
    avatar: avatarThumb,
    name: "Jack",
    designation: "Web & Community",
  },    
];

export default data;
