import checkIcon from "../../images/icon/roadmap_check.svg";
import blankIcon from "../../skeleton-images/BLANK_ICON.png";

// import { Ring } from 'react-spinners-css';

const data = [
    {
        icon: checkIcon,
        title: "Phase 01 ",
        subtitle: "Launch of our socials.",
        text: <>Launch of our socials <br /><br />We are opening Website, twitter and discord for our community! Everyone is welcome, doesn’t matter if You own a Boney or not, you may feel yourself at home in here</>
    }, 
    {
        icon: blankIcon,
        title: "Phase 02",
        subtitle: "Awakening",
        text: <>Boney Early Adopters Awakening <br /><br />Exlusive mint of Genesis Boney’s for early members of the community <br /><br />Limited number</>
    }, 
    {
        icon: blankIcon,
        title: "Phase 03",
        subtitle: "General mint",
        text: <>Boney general mint <br /><br />Public of Genesis Boney’s collection. Those Boney’s will be available only during this mint <br /><br />Limited number</>
    }, 
    {
        icon: blankIcon,
        title: "Phase 04",
        subtitle: "Accesories drop",
        text: <>Boney Accessories drop <br /><br />Public mint and airdrops for community members of accessories that can be used on your Boney’s</>
    }, 
    {
        icon: blankIcon,
        title: "Phase 05 and on",
        subtitle: "hidden", 
        // text: <div style={{display:'flex',justifyContent:'center'}}>
        //     <Ring color="white" size={40}/>
        // </div>
        text: <>In progress...</>
    }, 
]

export default data;