const RoadMapItem = ({ id, icon, title, text }) => {
  return (
    <div
      className={
        id % 2 === 0
          ? `v2_roadmap_card_item v2_roadmap_card_item_odd`
          : `v2_roadmap_card_item v2_roadmap_card_item_even`
      }
    >
      <div className="v2_roadmap_card_box"></div>
      <div className="v2_roadmap_card_content">
        <div className="roadmap-header_wrapper">
          <h3>
            {title}{" "}
            <span>
              <img src={icon} alt="road map" />
            </span>
          </h3>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default RoadMapItem;
