import { FaDiscord, FaTwitter } from "react-icons/fa";
import SectionTitle from "../../../../common/sectionTitle";
import Button from "../../../../common/button";

import BonesCrossedIcon from "../../../../assets/skeleton-images/crossed1.png"

import CTAStyleWrapper from "./Cta.style";

const CTA = () => {
  const particleShapes = [
    BonesCrossedIcon,
    BonesCrossedIcon,
    BonesCrossedIcon,
    BonesCrossedIcon,
    BonesCrossedIcon,
    BonesCrossedIcon,
  ];
  return (
    <CTAStyleWrapper className="cta_wrapper_v1">
      <div className="container">
        <SectionTitle
          className="bithu_title_section text-center"
          title="Join Our community &amp; get Early access"
        />
        <div className="title-emo"><img src={BonesCrossedIcon} alt="Crossed Bones" /></div>
        <div className="bithu_v1_cta_content">
          <div className="join_comunity_btns">
            <Button lg variant="alternative" className="join_discord_btn">
              {" "}
              <FaDiscord /> coming soon
            </Button>

            <Button lg variant="alternative" className="twitter_btn">
              {" "}
              <FaTwitter /> coming soon
            </Button>
          </div>

          <div className="footer_stras_sect">
            <div className="footer_stars">
              {particleShapes?.map((shape, i) => (
                <span key={i} className={`star_${i + 1}`}>
                  <img src={shape} alt="bithu nft background particle" />
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CTAStyleWrapper>
  );
};

export default CTA;
