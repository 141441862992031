// import { useModal } from "../../../../utils/ModalContext";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/skeleton-images/25degrees2.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";

import skeleton1 from '../../../../assets/skeleton-images/1.png';
import skeleton2 from '../../../../assets/skeleton-images/2.png';
import skeleton3 from '../../../../assets/skeleton-images/3.png';

const Banner = () => {
  // const { mintModalHandle } = useModal();
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>Whitebones & Genesis Boney's</h2>
              <div className="banner_buttons">
                <Button lg variant="mint"

                style={{cursor: "auto"}}

                // Uncomment to enable onClick

                //  onClick={() => mintModalHandle()}
                >
                  {" "}
                  Coming soon
                </Button>
              </div>
              <div className="three-skeletons">
                <img src={skeleton1} alt="" />
                <img src={skeleton2} alt="" />
                <img src={skeleton3} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avatar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
