import styled, { keyframes } from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/h1_banner_bg-min.png";

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const BannerV1Wrapper = styled.section`
  background: url(${sectionBgImage});
  min-height: 950px;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 150px;
  position: relative;

  .bithu_v1_baner_left {
    text-align: center;
  }

    h2 {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 32px;
    }

    h3 {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      margin-top: 58px;
      justify-content: center;
    }

    .coin-info {
      display: flex;
      flex-direction: column;
      margin-top: 37px;
      span {
        font-family: "Bakbak One";
        font-size: 18px;
        line-height: 36px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);

        & .highlighted {
          color: #B27F4B;
        }
      }
    }
  }

  .bithu_v1_baner_right {
    max-width: 500px;
    width: 80%;
    margin-left: auto;
    margin-top: 0px;

    .mint_live_circle_sect {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      border: none;
      background: #F6CCA1;
      position: absolute;
      top: -30px;
      left: -30px;
      z-index: 1;

      .mint_live_circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .mint_live_text {
          animation: ${rotate} 20s linear infinite;
          position: absolute;
        }
      }
    }

    .bithu_v1_baner_right_img_sect {
      position: relative;
      width: 90%;
      height: 400px;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .bithu_v1_baner_right_img_bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .bithu_v1_baner_right_img {
        position: relative;
        z-index: 1;
      }
    }
  }

  .bithu_v1_baner_left {
    max-width: 500px;
    margin-top: 50px;
  }

  .three-skeletons {
    width: 1200px;
    height: 400px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-between;

    img {
      width: 300px;
      height: 300px;
    }
  }

  @media only screen and (max-width: 1199px) {
    height: 1000px;
    min-height: 0;

    .bithu_v1_baner_left {
      h2 {
        font-size: 50px;
        line-height: 65px;
      }

      h3 {
        font-size: 34px;
        line-height: 38px;
      }

      .baner_buttons {
        margin-top: 40px;
      }
    }

    .three-skeletons {
      width: 800px;
      height: 200px;
      bottom: 20%;
  
      img {
        width: 300px;
        height: 300px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    height: 800px;
    padding-top: 130px;

    .col-lg-6 {
      text-align: center;
    }

    .bithu_v1_baner_left {
      margin: 0 auto;
    }


    .bithu_v1_baner_right {
      display: none;
    }

    .three-skeletons {
      width: 750px;
      height: 250px;
      bottom: 10%;
  
      img {
        width: 250px;
        height: 250px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    height: 700px;

    .three-skeletons {
      width: 600px;
      height: 200px;
      img {
        width: 200px;
        height: 200px;
      }
    }
  }

  @media only screen and (max-width: 560px) {
    height: 650px;

    .three-skeletons {
      width: 450px;
      height: 150px;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    height: 700px;

    .three-skeletons {
      width: 360px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }

    .bithu_v1_baner_left {
      h2 {
        font-size: 37px;
      }

      h3 {
        font-size: 22px;
      }

      .coin-info {
        font-size: 14px;
        line-height: 29px;
      }
    }

    .bithu_v1_baner_right {
      padding-left: 34px;

      .mint_live_circle_sect{
        height: 90px;
        width: 90px;
        top: -36px;
        left: -40px;
    }
    }
  }

  @media (max-width: 375px) {
    height: 600px;

    .bithu_v1_baner_left {
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 360px) {
    .bithu_v1_baner_left {
      .coin-info {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 313px) {
    height: 700px;

    .three-skeletons {
      width: 240px;
      height: 80px;
      bottom: 20%;
      img {
        width: 80px;
        height: 80px;
      }
  }
`;

export default BannerV1Wrapper;
