// import { FaDiscord, FaTwitter } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/skeleton-images/whitebonesshadowless.svg";

import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            {" "}
            <BsXLg />{" "}
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li>
              <a href="#home"
              onClick={() => mobileMenuhandle()}
              >Home</a>
            </li>
            <li>
              <a href="#about"
              onClick={() => mobileMenuhandle()}
              >About</a>
            </li>
            <li>
              <a href="#team"
              onClick={() => mobileMenuhandle()}
              >Team</a>
            </li>
            <li>
              <a href="#roadmap"
              onClick={() => mobileMenuhandle()}
              >Roadmap</a>
            </li>
            <li>
              <a href="#faq"
              onClick={() => mobileMenuhandle()}
              >FAQ</a>
            </li>
          </ul>
        </div>
        {/* <div className="mobile_menu_social_links">
          <a href="# ">
            <FaTwitter />
          </a>
          <a href="# ">
            <FaDiscord />
          </a>
        </div> */}
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
