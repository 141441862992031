import styled from "styled-components";
import footerBG from "../../../../assets/images/bg/v1_footer_bg-min.png";

const FooterStyleWrapper = styled.footer`
  background: url(${footerBG});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .bithu_v1_main_footer {
    position: relative;
    min-height: 400px;
    width: 100%;
    .bithu_v1_main_footer_overlay {
      background: linear-gradient(
        180deg,
        rgba(4, 15, 21, 0) 0%,
        rgba(4, 14, 20, 0.270833) 50.52%,
        #040c12 100%
      );
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }

    .three_charectre_img {
      text-align: center;
    }

    /* footer bottom */
    .footer_bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;
      min-height: 100px;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(20px):
    }

    .footer_logo {
      width: 200px;
    }

    .footer_bottom_content {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .footer_shapes_left {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      z-index: 0;
    }
    .footer_shapes_right {
      position: absolute;
      right: 0px;
      top: 0px;
      height: 100%;
      z-index: 0;
    }
    .footer_menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 100px;
      position: relative;
      z-index: 1;
    }
    .bottom_footer_left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      .copiright_text {
        p {
          margin: 0 0 0 40px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
    .bact_to_top_btn {
      background: transparent;
      border: none;
      outline: none;
    }

    .bottom_footer_right {
      max-width: 394px;
      width: 100%;
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 0 auto;
        padding: 0;
        li {
          &:hover {
            a {
              color: #ffffff;

              img {
                opacity: 1;
              }
            }
          }
        }

        a {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 500;
          font-size: 16px;
          line-height: 40px;
          text-align: right;
          transition: 0.4s;
          text-decoration: none;
          img {
            width: 20px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .footer-wrapper {
    height: 650px;
  }

  .bithu_v1_main_footer {
    min-height: 267px;
    }
}

@media (max-width: 1399px) {
  .footer-wrapper {
    height: 650px;
  }

  .bithu_v1_main_footer {
    position: relative;
    min-height: 200px;
    width: 100%;
    .bithu_v1_main_footer_overlay {
      background: linear-gradient(
        180deg,
        rgba(4, 15, 21, 0) 0%,
        rgba(4, 14, 20, 0.270833) 50.52%,
        #040c12 100%
      );
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
}

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bithu_v1_main_footer {
      .bottom_footer_right {
        ul {
          justify-content: right;
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .footer-wrapper {
      height: 540px;
    }

    .bithu_v1_main_footer {
      position: relative;
      min-height: 183px;
      width: 100%;
      .bithu_v1_main_footer_overlay {
        background: linear-gradient(
          180deg,
          rgba(4, 15, 21, 0) 0%,
          rgba(4, 14, 20, 0.270833) 50.52%,
          #040c12 100%
        );
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }

    .bithu_v1_main_footer {
      .bottom_footer_right {
        ul {
          li + li {
            padding-left: 20px;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bithu_v1_main_footer {
      .bottom_footer_right {
        ul {
          justify-content: space-between;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .footer-wrapper {
      height: 548px;
    }

    .bithu_v1_main_footer {
      position: relative;
      min-height: 162px;
      width: 100%;
      .bithu_v1_main_footer_overlay {
        background: linear-gradient(
          180deg,
          rgba(4, 15, 21, 0) 0%,
          rgba(4, 14, 20, 0.270833) 50.52%,
          #040c12 100%
        );
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
      }

    .bithu_v1_main_footer {
      .bottom_footer_left {
        .copiright_text a {
          font-size: 14px;
          padding-left: 22px;
        }

        .footer_bottom_content {
          justify-content: space-between;
        }

        .footer_logo {
          max-width: 200px;
        }
      }

      .bottom_footer_right {
        max-width: 300px;
        ul {
          li + li {
            padding-left: 15px;
          }
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .footer-wrapper {
      height: 640px;
    }

    .bithu_v1_main_footer {
      min-height: 266px;
      }

    .bithu_v1_main_footer {
      .footer_bottom {
        min-height: 140px;
      }
      .footer_menu {
        padding-top: 20px;
        flex-direction: column;
        padding-bottom: 0px;
      }

      .footer_shapes_left,
      .footer_shapes_right {
        display: none;
      }

      .bact_to_top_btn {
        position: absolute;
        bottom: 10px;
      }
      .bottom_footer_right {
        margin: 10px auto 10px auto;
      }
    }
  }

  @media (max-width: 575px) {
    .footer-wrapper {
      height: 580px;
    }

    .bithu_v1_main_footer {
      min-height: 251px;
      }
  }

  @media (max-width: 461px) {
    .footer-wrapper {
      height: 550px;
    }

    .bithu_v1_main_footer {
      min-height: 176px;
      }
  }

  
  @media (max-width: 425px) {
    .footer-wrapper {
      height: 600px;
    }

    .bithu_v1_main_footer {
      min-height: 186px;
      }
  }

  @media (max-width: 332px) {
    .footer-wrapper {
      height: 650px;
    }

    .bithu_v1_main_footer {
      min-height: 191px;
      }
  }

  @media (max-width: 280px) {
    .footer-wrapper {
      height: 700px;
    }

    .bithu_v1_main_footer {
      min-height: 196px;
      }
  }

  @media only screen and (max-width: 375px) {
    .bithu_v1_main_footer {
      .bottom_footer_left {
        .copiright_text {
          margin-left: 26px;
        }
      }

      .footer_menu {
        row-gap: 12px;
      }
    }
  }
`;

export default FooterStyleWrapper;
