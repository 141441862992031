const data = [
  {
    title: "What is a Boney?",
    text: "Every Boney plays a very important role in our WhiteBones ecosystem",
  },
  {
    title: "What is the supply of Genesis Boney’s?",
    text: "There will be a total of 5555 Genesis Boney’s",
  },
  {
    title: "Will there be subsequent mints?",
    text: "Yes, but every subsequent mint will be less unique",
  },
  {
    title: "How many traits are there in the Genesis Boney Collection?",
    text: "We have over 120 hand-drawn traits across 10 categories to make sure there’s a vast variety of Genesis Boneys!",
  },
  {
    title: "Is WhiteBones a financial investment?",
    text: "WhiteBones should not be considered an investment, please consider the blockchain volatility before buying a WhiteBones NFTs",
  },
  {
    title: "What is a mint cost?",
    text: "Mint cost of each Boney will be announced closer to the release date!",
  },
  {
    title: "Why should I mint a Boney?",
    text: "WhiteBones is working towards establishing a worldwide brand through the creation of unique NFTs, which will offer value to their holders through various features such as mini games, rewards, merchandise etc. You will be able to read more about it in our upcoming Bone Paper",
  },
];

export default data;
